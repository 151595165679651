import { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';

const Navigation = () => {
    
    const [checked, setChecked] = useState(false);

    const closeNav = () => {
        setChecked(false);
    }
    
    return (
        <div className="navigation">
        <input type="checkbox" 
        id="navi-toggle" 
        checked={checked} 
        onChange={(e) => setChecked(e.target.checked)} 
        className="navigation__checkbox" />
        <label htmlFor="navi-toggle" className="navigation__button">
            <span className="navigation__icon"></span>
        </label>

        <div className="navigation__background"></div>
        <nav className="navigation__nav">
            <ul className="navigation__list">
                <li className="navigation__item">
                    <Link to="#section-about" onClick={closeNav} className="navigation__link"><span>01</span>About Stephen</Link>
                </li>
                <li className="navigation__item">
                    <Link to="#section-projects" onClick={closeNav} className="navigation__link"><span>02</span>Projects</Link>
                </li>
                <li className="navigation__item">
                    <Link to="#section-news" onClick={closeNav} className="navigation__link"><span>03</span>News &#38; Updates</Link>
                </li>
                <li className="navigation__item">
                    <Link to="#section-contact" onClick={closeNav} className="navigation__link"><span>04</span>Contact</Link>
                </li>
            </ul>
        </nav>
    </div>
    );

};

export default Navigation;