const Contact = () => {
    return (
        <section className="section-contact" id="section-contact">
            <div className="row">
                <div className="contact">
                    <div className="contact__form">
                        <div className="u-margin-bottom-medium">
                            <h2 className="heading-secondary">
                                Let's talk
                            </h2>
                            <h3 className="heading-tertiary">
                                If you like what you see here in my content, feel free to reach out
                            </h3>
                        </div>
                        <img srcSet="assets/images/sdw-contact.jpg 600w, assets/images/sdw-contact-large.jpg 1000w"
                            src="assets/images/sdw-contact-large.jpg" alt="Contact me pic"
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 600px"
                            className="contact__photo" />
                        <div className="contact__links">
                            <ul className="contact__list">
                                <li className="contact__item">
                                    <a href="mailto:author@stephendaltonwrites.com" target="_blank" className="contact__link">
                                        <i className="contact__icon las la-envelope"></i>
                                        <span className="contact__icon-label">Email</span>
                                    </a>
                                </li>
                                <li className="contact__item">
                                    <a href="https://twitter.com/messages/compose?recipient_id=1350844189261455363" target="_blank" className="contact__link">
                                    <i className="contact__icon lab la-twitter"></i>
                                        <span className="contact__icon-label">Twitter</span>
                                    </a>
                                </li>
                                <li className="contact__item">
                                    <a href="https://www.linkedin.com/in/stephen-dalton-dev-and-design" target="_blank" className="contact__link">
                                    <i className="contact__icon lab la-linkedin-in"></i>
                                        <span className="contact__icon-label">Linkedin</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

};

export default Contact;