import { Fragment } from 'react';
import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';
import About from './About';
import Ribbon from './Ribbon';
import Projects from './Projects';
import News from './News';
import Contact from './Contact';
import HardLuckPopup from './HardLuckPopup';

const Main = () => {
    return (
        <Fragment>
            <Navigation />
            <Header />
            <main>
                <About />
                <Ribbon />
                <Projects />
                <News />
                <Contact />
            </main>
            <Footer />
            <HardLuckPopup />
        </Fragment>
    );

};

export default Main;