const Projects = () => {
    return (
        <section className="section-ribbon">
            <div className="u-center-text u-text-pop">
                <q className="page-quote">All of reality, everything we experience is all within our minds.
                    This is why stories, even the most fantastical, can be real- the difference between real and
                    imagined is just a line within your head.</q></div>
        </section>
    );

};

export default Projects;