import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReducedHeader from './ReducedHeader';
import Footer from './Footer';
import HardLuckPopup from './HardLuckPopup';

const NewsArchive = () => {
    return (
        <Fragment>
            <ReducedHeader />
            <main>
                <nav className="navigation__navbar">
                    <Link to="/" className="btn-text btn-text--sink">&larr; Home</Link>
                </nav>
                <section className="section-news" id="section-news-archive">
                    <div className="u-center-text u-margin-bottom-large">
                        <h2 className="heading-secondary heading-secondary--solid">
                            News &amp; Update Archive
                        </h2>
                    </div>
                    <div className="row">
                        <div className="news">
                            <div className="news__text">
                                <h3 className="heading-tertiary u-margin-bottom-small">
                                    2023-07-09 - The Bite of Madness
                                </h3>
                                <p>
                                    Humph, it's been a while, but it's finally here. The next story in my Icelandic faerie tale anthology:
                                    The Bite of Madness. A tale of a girl unhappy with what fate has woven for her.
                                    A tale of envy and madness.<br /><br />
                                    Read: <a className="news__story-link" href="https://www.inkitt.com/stories/899014/chapters/4" target="_blank">The Bite of Madness</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="news">
                            <div className="news__text">
                                <h3 className="heading-tertiary u-margin-bottom-small">
                                    2022-06-28 - Bright Prince
                                </h3>
                                <p>
                                    The next story in my Icelandic faerie tale anthology is here:
                                    Bright Prince. A tale of two villages at odds. A tale of a boy and girl on the brink of adulthood.
                                    And a tale of lust and its dark consequence.<br /><br />
                                    Read: <a className="news__story-link" href="https://www.inkitt.com/stories/899014/chapters/3" target="_blank">Bright Prince</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="news">
                            <div className="news__text">
                                <h3 className="heading-tertiary u-margin-bottom-small">
                                    2022-05-19 - The Silencer
                                </h3>
                                <p>
                                    Yes, so I broke down and posted The Silencer for the world to read. So go ahead and join Charlotte Chu on her journey from frustrated teenage orphan to The Silencer.<br /><br />
                                    Read: <a className="news__story-link" href="https://www.inkitt.com/stories/907053" target="_blank">The Silencer</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="news">
                            <div className="news__text">
                                <h3 className="heading-tertiary u-margin-bottom-small">
                                    2022-05-04 - Gifts of Yule
                                </h3>
                                <p>
                                    I've released the next story in my Icelandic faerie tale anthology:
                                    Gifts of Yule. In the well of grief and hardship, baited by grasping greed,
                                    Halvar learns the dangers of treating with the volitile fay.<br /><br />
                                    Read: <a className="news__story-link" href="https://www.inkitt.com/stories/899014/chapters/2" target="_blank">Gifts of Yule</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="news">
                            <div className="news__text">
                                <h3 className="heading-tertiary u-margin-bottom-small">
                                    2022-04-28 - The Big Reveal
                                </h3>
                                <p>
                                    It's here! This is the launch of my new, actual effort made, website.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="news">
                            <div className="news__text">
                                <h3 className="heading-tertiary u-margin-bottom-small">
                                    2022-04-01 - New Website Under Construction
                                </h3>
                                <p>
                                    I've always felt a little embarrassed by my website because it was pretty
                                    novice-level. I think as a software developer who kicks out awesome websites
                                    professionally I should be able to do a little better. So, a brand new, from
                                    scratch website is coming soon.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="news">
                            <div className="news__text">
                                <h3 className="heading-tertiary u-margin-bottom-small">
                                    2021-06-02 - Debut: The Darkness Nibbling At My Toes
                                </h3>
                                <p>
                                    Trying out various ways of reaching an audience, I have debuted a new story: Read The Darkness Nibbling At My Toes
                                    for free on <a href="https://www.inkitt.com/stories/scifi/738571" target="_blank">Inkitt</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="news">
                            <div className="news__text">
                                <h3 className="heading-tertiary u-margin-bottom-small">
                                    2021-05-10 - Welcome
                                </h3>
                                <p>
                                    Proud to launch my new author web page. If you've found your way here, welcome and I hope you enjoy.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <HardLuckPopup />
        </Fragment>
    );

};

export default NewsArchive;