import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {
    return (
        <header className="header" id="header">
            <div className="header__logo-box">
                <a href="#popup">
                    <img srcSet="assets/images/logo-1x.png,
                            assets/images/logo-2x.png 2x"
                        src="assets/images/logo-2x.png"
                        alt="Logo"
                        className="header__logo" />
                </a>
            </div>
            <div className="header__text-box">
                <h1 className="heading-primary">
                    <span className="heading-primary--main">Stephen Dalton</span>
                    <span className="heading-primary--sub">Writes</span>
                </h1>
                <q className="heading-primary--tagline">Crafting stories of magic and adventure, love and conflict, but always and most importantly, stories to entertain</q>
            </div>
        </header>
    );

};

export default Header;