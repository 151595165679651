
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Main from "./js/components/Main";
import NewsArchive from "./js/components/NewsArchive";
import NoPage from "./js/components/NoPage";

function Router() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="news" element={<NewsArchive />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
  );
}

export default Router;
