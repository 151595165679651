import { useRef } from "react";

const Popup = (popupContent) => {
    const inputRef = useRef(null);
    const closePopup = () => {
        inputRef.current.click();
    }
    
    return (
        <div className="popup" id="popup">
            <div className="popup__backdrop" onClick={closePopup}></div>
            <div className="popup__content">
                <div className="popup__left">
                    <img src={popupContent.image} alt="Popup image" className="popup__img" />
                </div>
                <div className="popup__right">
                    <a href="#header" ref={inputRef} className="popup__close">&times;</a>
                    <h2 className="heading-secondary u-margin-bottom-small">{popupContent.header}</h2>
                    <p className="popup__text">
                        {popupContent.text}
                    </p>
                </div>
            </div>
        </div>
    );

};

export default Popup;