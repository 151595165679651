const Footer = () => {
    return (
        <footer className="footer">
            <div className="row">
                <div className="col-1-of-4">
                    <div className="footer__logo-box">
                        <picture className="footer__logo">
                            <source srcSet="assets/images/logo-white-small-1x.png 1x, assets/images/logo-white-small-2x.png 2x" media="(max-width: 37.5em)" />
                            <img srcSet="assets/images/logo-white-1x.png 1x, assets/images/logo-white-2x.png 2x" src="assets/images/logo-white-2x.png" alt="Full logo" />
                        </picture>
                    </div>
                </div>
                <div className="col-3-of-4">
                    <p className="footer__copyright">
                        Built by <a href="/" className="footer__link">Stephen Dalton</a>. Copyright &copy; by Stephen Dalton.
                    </p>
                    <p>
                        All photos on this page were taken by Stephen and are likewise his Copyright &copy;.
                    </p>
                </div>
            </div>
        </footer>
    );

};

export default Footer;