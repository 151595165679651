const Projects = () => {
    return (
        <section className="section-projects" id="section-projects">
            <div className="u-center-text u-margin-bottom-large">
                <h2 className="heading-secondary">
                    Projects
                </h2>
            </div>
            <div className="row">
                <div className="col-1-of-3">
                    <div className="card">
                        <div className="card__side card__side--front">
                            <div className="card__picture card__picture--1"></div>
                        </div>
                        <div className="card__side card__side--back card__side--back-1">

                            <h4 className="card__heading">
                                <span className="card__heading-span card__heading-span--1">
                                    Land Of Giants
                                </span>
                            </h4>
                            <div className="card__details">
                                <p>
                                    Zaya is a barmaid in the scummiest tavern in the Kingdom (an official title). 
                                    When magical &amp; mysterious Lucky shows up, Zaya must balance her lust for magic with 
                                    rescuing of thousands of woebegone humans enslaved- in the Land of Giants.
                                </p>                  
                            </div>
                            <div className="card__cta">
                                <a href="https://www.inkitt.com/stories/881235" target="_blank" className="btn btn--white">Read now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-1-of-3">
                    <div className="card">
                        <div className="card__side card__side--front">
                            <div className="card__picture card__picture--2"></div>
                        </div>
                        <div className="card__side card__side--back card__side--back-2">

                            <h4 className="card__heading">
                                <span className="card__heading-span card__heading-span--2">
                                    The Silencer
                                </span>
                            </h4>
                            <div className="card__details">
                                <p>
                                Foster kid, Charlotte Chu, has a new talent- call it more of an obsession: murder. At the same time 
                                she finds herself in love with Divya Singh- amateur investigator and daughter of a cop. 
                                <br /><br />Being an orphan teenage murderer in love is hard.
                                </p>
                            </div>
                            <div className="card__cta">
                                <a href="https://www.inkitt.com/stories/907053" target="_blank" className="btn btn--white">Read now</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-1-of-3">
                    <div className="card">
                        <div className="card__side card__side--front">
                            <div className="card__picture card__picture--3"></div>
                        </div>
                        <div className="card__side card__side--back card__side--back-3">

                            <h4 className="card__heading">
                                <span className="card__heading-span card__heading-span--3">
                                    The 7 Consequences
                                </span>
                            </h4>
                            <div className="card__details">
                                <p>
                                Rediscover the lost tales of the fay of Iceland with the traditional understanding that 
                                the faerie realm was a dark and dangerous place. Each tale explores the consequence of 
                                one of the seven classic Norse immoralities.<br /><br />This is an ongoing release novel. 
                                These faerie tales are not those found in your kid's story books.
                                </p>
                            </div>
                            <div className="card__cta">
                                <a href="https://www.inkitt.com/stories/899014" target="_blank" className="btn btn--white">Read now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

};

export default Projects;