import Popup from './Popup';

const HardLuckPopup = () => {
    const popupContent = {
        image: "assets/images/hardluckclear.png",
        header: "The Hard Luck Story",
        text: <span>
            <span>Hard-Luck Coyote is a brand that Stephen attaches to all his creative endeavours. 
                The name is the child of two narratives:</span><br/><br/><span>On the day that Stephen's daughter was born, 
                he and his wife were driving to the hospital early in the morning and almost hit a coyote darting across the 
                street. The coyote continued to lope beside the road for a time and Stephen marked how skinny and disheveled 
                it looked- a "hardluck" coyote.</span><br/><br/><span>The other root is the Coyote figure in indigenous stories. Coyote is 
                intelligent and mischievous, and one of Stephen's favorite protagonists.</span>
                <br/><br/><span>The signifigance of these influences have birthed Hard-Luck Coyote.</span>
            </span>
    };

    return (
        <Popup
            image={popupContent.image}
            header={popupContent.header}
            text={popupContent.text}
        />
    );

};

export default HardLuckPopup;