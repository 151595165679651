import { Link } from 'react-router-dom';

const News = () => {
    return (
        <section className="section-news" id="section-news">
            <div className="bg-video">
                <video className="bg-video__content" autoPlay muted loop>
                    <source src="assets/images/video.mp4" type="video/mp4" />
                    Your browser is not supported. Sorry.
                </video>
            </div>
            <div className="u-center-text u-margin-bottom-large">
                <h2 className="heading-secondary">
                    News &amp; Updates
                </h2>
            </div>
            <div className="row">
                <div className="news">
                    <div className="news__text">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            2023-07-09 - The Bite of Madness
                        </h3>
                        <p>
                            Humph, it's been a while, but it's finally here. The next story in my Icelandic faerie tale anthology:
                            The Bite of Madness. A tale of a girl unhappy with what fate has woven for her.
                            A tale of envy and madness.<br /><br />
                            Read: <a className="news__story-link" href="https://www.inkitt.com/stories/899014/chapters/4" target="_blank">The Bite of Madness</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="news">
                    <div className="news__text">
                        <h3 className="heading-tertiary u-margin-bottom-small">
                            2022-06-28 - Bright Prince
                        </h3>
                        <p>
                            The next story in my Icelandic faerie tale anthology is here:
                            Bright Prince. A tale of two villages at odds. A tale of a boy and girl on the brink of adulthood.
                            And a tale of lust and its dark consequence.<br /><br />
                            Read: <a className="news__story-link" href="https://www.inkitt.com/stories/899014/chapters/3" target="_blank">Bright Prince</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="u-center-text u-margin-top-large">
                <Link to="/news" className="btn-text">Read all stories &rarr;</Link>
            </div>
        </section>
    );

};

export default News;