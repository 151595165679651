const ReducedHeader = () => {
    return (
        <header className="header-reduced">
            <div className="header-reduced__logo-box">
                <a href="#popup">
                    <img srcSet="assets/images/logo-1x.png,
                            assets/images/logo-2x.png 2x"
                        src="assets/images/logo-2x.png"
                        alt="Logo"
                        className="header-reduced__logo" />
                </a>
            </div>
            <div className="header-reduced__text-box">
                <h1 className="heading-reduced-primary">
                    <span className="heading-reduced-primary--main">Stephen Dalton</span>
                    <span className="heading-reduced-primary--sub">Writes</span>
                </h1>
            </div>
        </header>
    );

};

export default ReducedHeader;