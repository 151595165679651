const About = () => {
    return (
        <section className="section-about" id="section-about">
            <div className="u-center-text u-margin-bottom-large">
                <h2 className="heading-secondary">
                    About Stephen K Dalton
                </h2>
                <q className="page-quote">Sharing stories is the tradition of humanity.</q>
            </div>
            <div className="row">
                <div className="col-2-of-3">
                    <h3 className="heading-tertiary u-margin-bottom-small">
                        Personal History
                    </h3>
                    <p className="paragraph">
                        I am a Canadian, born in Calgary, Alberta. As a child, my family moved a few times, once to live in the U.S. for a couple years, in
                        Columbus, Ohio. Then we moved again, back to Canada, to Victoria, British Columbia. As an adult I have moved around some as well, living
                        in Ontario for a time before coming full circle and moving back to Calgary.
                    </p>
                    <p className="paragraph">
                        I think that in part this moving is what has made me a storyteller. While a love of reading and a family that inspired creativity also
                        played big parts, moving- leaving behind so much of what I knew and all my friends, several times- made me imagine worlds that could be
                        familiar and friendly. It also let me understand real journey and change. Now I enjoy journeys wherever I can find them, either in the
                        stories told by others, the stories I can tell, or when possible, real journeys around the globe.
                    </p>
                </div>
                <div className="col-1-of-3">
                    <div className="composition">
                        <img srcSet="assets/images/sdw-about1.jpg 300w, assets/images/sdw-about1-large.jpg 1000w"
                            src="assets/images/sdw-about1-large.jpg" alt="Photo 1"
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            className="composition__photo composition__photo--p1" />
                        <img srcSet="assets/images/sdw-about2.jpg 300w, assets/images/sdw-about2-large.jpg 1000w"
                            src="assets/images/sdw-about2-large.jpg" alt="Photo 2"
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            className="composition__photo composition__photo--p2" />
                        <img srcSet="assets/images/sdw-about3.jpg 300w, assets/images/sdw-about3-large.jpg 1000w"
                            src="assets/images/sdw-about3-large.jpg" alt="Photo 3"
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            className="composition__photo composition__photo--p3" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2-of-3">
                    <h3 className="heading-tertiary u-margin-bottom-small">
                        Professional History
                    </h3>
                    <p className="paragraph">
                        After graduating university I landed a job as a Game Designer, making full use of my technical expertise, creativity and love of telling
                        stories. I spent the next seven years at a studio famous for games that mixed action and eldritch content. The lessons learned from
                        crafting games have fused themselves into my storytelling in a way that seeks to give readers not just a story but a feeling of absorption
                        and engagement.
                    </p>
                    <p className="paragraph">
                        From all of these experiences, I have learned the importance of Flow, as described by the psychologist, Mihaly Csikszentmihalyi.
                        Having flow is not foreign to novels, but there is a certain concept of Flow that is more often purposely injected into video games.
                        It is the feeling of absorption and engagement, where the player, or reader in this case, is on that perfect roller coaster of a
                        novel, and while there are highs and lows, they are never too high or too low to make the reader want to stop. It is this Flow,
                        learned from crafting games, that I seek to give to readers of my work.
                    </p>
                </div>
                <div className="col-1-of-3">
                    <div className="composition">
                        <img srcSet="assets/images/sdw-about4.jpg 300w, assets/images/sdw-about4-large.jpg 1000w"
                            src="assets/images/sdw-about4-large.jpg" alt="Photo 4"
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            className="composition__photo composition__photo--p4" />
                        <img srcSet="assets/images/sdw-about5.jpg 300w, assets/images/sdw-about5-large.jpg 1000w"
                            src="assets/images/sdw-about5-large.jpg" alt="Photo 5"
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            className="composition__photo composition__photo--p5" />
                        <img srcSet="assets/images/sdw-about6.jpg 300w, assets/images/sdw-about6-large.jpg 1000w"
                            src="assets/images/sdw-about6-large.jpg" alt="Photo 6"
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            className="composition__photo composition__photo--p6" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2-of-3">
                    <h3 className="heading-tertiary u-margin-bottom-small">
                        Writing History
                    </h3>
                    <p className="paragraph">
                        Writing and telling stories was always a hobby, but I never gave much thought to the technique and craft until recently.
                        At a moment of reflection I realized how much I enjoy writing and how much I want to tell stories to people,
                        and so I started writing with purpose. I have completed several manuscripts as well as several short stories and always have a
                        few more projects on the back burner.
                    </p>
                </div>
                <div className="col-1-of-3">
                    <div className="composition">
                        <img srcSet="assets/images/sdw-about7.jpg 300w, assets/images/sdw-about7-large.jpg 1000w"
                            src="assets/images/sdw-about7-large.jpg" alt="Photo 7"
                            sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                            className="composition__photo composition__photo--p7" />
                    </div>
                </div>
            </div>
        </section>
    );

};

export default About;